class LoginPage {
  constructor() {
    this.setupLoaderListener();
  }

  toggleLoader(enable) {
    const btn = document.getElementById('login-btn');
    const btnText = document.getElementById('login-btn-txt');
    if (enable) {
      btnText.style.display = "none";

      const loader = document.createElement("div");
      loader.classList.add("loader");
      btn.appendChild(loader)
    } else {
      const loader = document.getElementsByClassName('loader')[0];
      loader.remove();

      btnText.style.display = "initial";
    }
  }

  setupLoaderListener() {
    const form = document.getElementById('login_form');
    form.addEventListener('submit', async (event) => {
      event.preventDefault();
      this.toggleLoader(true);
      form.submit();
    });
  }
}

new LoginPage();
